import dashboard from "@/router/pages/agent/dashboard";
import profile from "@/router/pages/agent/profile";
import referrals from "@/router/pages/agent/referrals";
import payments from "@/router/pages/agent/payments";
import news from "@/router/pages/agent/news";
import advices from "@/router/pages/agent/advices";
import instruction from "@/router/pages/agent/instruction";
import agents from "@/router/pages/agent/agents";

const agent = [
  ...dashboard,
  ...profile,
  ...referrals,
  ...payments,
  ...news,
  ...advices,
  ...instruction,
  ...agents,
];

export default agent;
