import i18n from "@/plugins/vue-i18n";
import withdrawalStatus from "@/configs/withdrawalStatus.json";

export default {
  namespaced: true,
  state: {
    statuses: [
      {
        code: withdrawalStatus.new,
        label: i18n.t(`label.withdrawal.statuses.new`),
        color: "primary",
      },
      {
        code: withdrawalStatus.approved,
        label: i18n.t(`label.withdrawal.statuses.approved`),
        color: "success",
      },
      {
        code: withdrawalStatus.rejected,
        label: i18n.t(`label.withdrawal.statuses.rejected`),
        color: "danger",
      },
    ],
  },
};
