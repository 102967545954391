import store from "@/store";
import Meta from "@/router/meta";
import notify from "@/services/notify.service";
import storage from "@/services/storage.service";
import user from "@/configs/user.config.json";
import homePage from "@/configs/homePage.config.json";

export default async (to, from, next) => {
  const { isAuthenticatedUser } = store.getters;
  const isPublicPage = !to.meta.authorize || to.meta.authorize.includes(user.public);
  const isPrivatePage = to.meta.authorize && !to.meta.authorize.includes(user.public);
  const notifyData = storage.getObj("notify");

  // Meta data service
  Meta.setDocumentMeta(" - ", "ATMOSFERA", to, from);

  // Scroll up when all page is loaded
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  notify.clearAll();

  if (isPrivatePage || (isPublicPage && isAuthenticatedUser)) {
    await checkPersonalUserData();

    const { userType } = store.getters;

    if (userType) {
      const hasUserAccess = to.meta.authorize && to.meta.authorize.includes(userType);

      if (!hasUserAccess) {
        redirectToHomePage(userType);
      }
    }
  }

  async function checkPersonalUserData() {
    await store.dispatch("verifyAuth");

    const { isAuthenticatedUser, userType } = store.getters;

    if (isAuthenticatedUser && !userType) {
      await store.dispatch("getProfile");
    }
  }

  function redirectToHomePage(userType) {
    const homePageRouteNames = {
      [user.employee]: homePage.employee,
      [user.agent]: homePage.agent,
    };

    next({ name: homePageRouteNames[userType] });
  }

  if (notifyData) {
    const { type, message } = notifyData;

    notify[type](message);

    storage.removeObj("notify");
  }

  next();
};
