import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const Referral = () => import(/* webpackChunkName: "referral" */ "@/views/referral/index");

const referral = [
  {
    path: "/landing",
    name: "ReferralForm",
    component: Referral,
    meta: {
      title: i18n.t("title.referral.form"),
      authorize: [user.public, user.employee, user.agent],
    },
  },
];

export default referral;
