import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    localeCodes: {
      externalUser: "label.role.externalUser",
      internalUser: "label.role.internalUser",
    },
  },

  getters: {
    roleLabel(state) {
      const { localeCodes } = state;

      return (role) => (i18n.te(localeCodes[role]) ? i18n.t(localeCodes[role]) : role);
    },
  },
};
