import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const ProfileEdit = () => import(/* webpackChunkName: "profile" */ "@/views/agent/profile/edit");

const profileEdit = [
  {
    path: "edit",
    name: "ProfileEdit",
    component: ProfileEdit,
    meta: {
      title: i18n.t("title.profile.profileEdit"),
      authorize: [user.agent],
    },
  },
];

export default profileEdit;
