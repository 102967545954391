import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const ChangePassword = () =>
  import(/* webpackChunkName: "profile" */ "@/views/agent/profile/changePassword");

const changePassword = [
  {
    path: "change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: i18n.t("title.profile.changePassword"),
      authorize: [user.agent],
    },
  },
];

export default changePassword;
