<template>
  <div />
</template>

<script>
export default {
  name: "FeedbackWidget",

  // mounted() {
  //   const url = "https://crm.atmo.pro/upload/crm/site_button/loader_5_dpkd16.js";
  //   const script = document.createElement("script");

  //   script.src = url + "?" + ((Date.now() / 60000) | 0);
  //   script.async = true;

  //   const firstScript = document.getElementsByTagName("script")[0];

  //   firstScript.parentNode.insertBefore(script, firstScript);
  // },
};
</script>

<style>
.b24-widget-button-wrapper.b24-widget-button-position-bottom-right,
.b24-widget-button-wrapper.b24-widget-button-position-bottom-middle,
.b24-widget-button-wrapper.b24-widget-button-position-bottom-left {
  z-index: 10;
}
</style>
