import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const AgentList = () => import(/* webpackChunkName: "agents" */ "@/views/employee/agents/index");
const AgentAdd = () => import(/* webpackChunkName: "agents" */ "@/views/employee/agents/add");
const AgentEdit = () => import(/* webpackChunkName: "agents" */ "@/views/employee/agents/edit");

const agents = [
  {
    path: "agents",
    name: "AgentList",
    component: AgentList,
    meta: {
      title: i18n.t("title.agent.list"),
      authorize: [user.employee],
    },
  },
  {
    path: "agents/add",
    name: "AgentAdd",
    component: AgentAdd,
    meta: {
      title: i18n.t("title.agent.add"),
      authorize: [user.employee],
    },
  },
  {
    path: "agents/:id",
    name: "AgentEdit",
    component: AgentEdit,
    meta: {
      title: i18n.t("title.agent.edit"),
      authorize: [user.employee],
    },
  },
];

export default agents;
