import ApiService from "@/services/api.service";

export default {
  namespaced: true,

  state: {
    filterFields: null,
    filterQuery: null,
    filterToggle: true,
  },

  getters: {
    filterFields(state) {
      return state.filterFields;
    },
    filterQuery(state) {
      return state.filterQuery;
    },
    filterToggle(state) {
      return state.filterToggle;
    },
  },

  mutations: {
    SET_FILTER_FIELDS: (state, filterFields) => {
      state.filterFields = filterFields;
    },
    SET_FILTER_QUERY: (state, filterQuery) => {
      state.filterQuery = filterQuery;
    },
    SET_FILTER_TOGGLE: (state) => {
      state.filterToggle = !state.filterToggle;
    },
  },

  actions: {
    getFilterFields: (context, apiModule) => {
      return new Promise((resolve) => {
        ApiService.get(`api/config/filters/get?module_name=${apiModule}`).then((response) => {
          context.commit("SET_FILTER_FIELDS", response.data);
          resolve(response.data);
        });
      });
    },
  },
};
