import config from "@/configs/layout.config.json";
import objectPath from "object-path";
import { set } from "lodash";

// Common
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const UPDATE_LAYOUT_CONFIG = "updateLayoutConfig";

// Breadcrumbs
export const APPEND_BREADCRUMB = "appendBreadcrumb";
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";

// HTML Classes
export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export const ADD_CLASSNAME = "addClassName";
export const SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";

export default {
  state: {
    config: config,
    defaultConfig: config,
    breadcrumbs: [],
    classes: {},
    title: "",
  },

  getters: {
    layoutConfig(state) {
      return (path, defaultValue = null) => {
        return objectPath.get(state.config, path, defaultValue);
      };
    },

    layoutDefaultConfig(state) {
      return (path, defaultValue = null) => {
        return objectPath.get(state.defaultConfig, path, defaultValue);
      };
    },

    breadcrumbs(state) {
      return state.breadcrumbs;
    },

    pageTitle(state) {
      const { title } = state;
      let last = state.breadcrumbs[state.breadcrumbs.length - 1];

      if (title) {
        return title;
      } else if (last && last.title) {
        return last.title;
      }
    },

    getClasses(state) {
      return (position) => {
        return typeof position !== "undefined" ? state.classes[position] : state.classes;
      };
    },
  },

  actions: {
    [SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload);
    },

    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.commit(OVERRIDE_LAYOUT_CONFIG);
    },

    [UPDATE_LAYOUT_CONFIG](state, payload) {
      state.commit(UPDATE_LAYOUT_CONFIG, payload);
    },

    [SET_BREADCRUMB](state, payload) {
      state.commit(SET_BREADCRUMB, payload);
    },

    [ADD_BREADCRUMB](state, payload) {
      if (typeof payload === "object") {
        payload.forEach((item) => state.commit(APPEND_BREADCRUMB, item));
      } else {
        state.commit(APPEND_BREADCRUMB, payload);
      }
    },

    [ADD_BODY_CLASSNAME](context, className) {
      document.body.classList.add(className);
    },

    [REMOVE_BODY_CLASSNAME](context, className) {
      document.body.classList.remove(className);
    },

    [ADD_CLASSNAME](context, payload) {
      context.commit(SET_CLASSNAME_BY_POSITION, payload);
    },
  },

  mutations: {
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload;
    },

    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.config = Object.assign({}, state.config, JSON.parse(localStorage.getItem("config")));
    },

    [UPDATE_LAYOUT_CONFIG](state, payload) {
      set(state.config, payload.path, payload.value);
    },

    [APPEND_BREADCRUMB](state, payload) {
      state.breadcrumbs = [...state.breadcrumbs, payload];
    },

    [SET_BREADCRUMB](state, payload) {
      state.breadcrumbs = payload;
    },

    [SET_CLASSNAME_BY_POSITION](state, payload) {
      const { position, className } = payload;

      if (!state.classes[position]) {
        state.classes[position] = [];
      }

      state.classes[position].push(className);
    },

    SET_PAGE_TITLE(state, title) {
      state.title = title;
    },
  },
};
