import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const Payments = () => import(/* webpackChunkName: "payments" */ "@/views/agent/payments");

const payments = [
  {
    path: "/payments",
    name: "Payments",
    component: Payments,
    meta: {
      title: i18n.t("title.payments._"),
      authorize: [user.agent],
    },
  },
];

export default payments;
