import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const RoleList = () => import(/* webpackChunkName: "roles" */ "@/views/employee/roles/index");
const RoleAdd = () => import(/* webpackChunkName: "roles" */ "@/views/employee/roles/add");
const RoleEdit = () => import(/* webpackChunkName: "roles" */ "@/views/employee/roles/edit");

const roles = [
  {
    path: "roles",
    name: "RoleList",
    component: RoleList,
    meta: {
      title: i18n.t("title.permissions"),
      authorize: [user.employee],
    },
  },
  {
    path: "roles/add",
    name: "RoleAdd",
    component: RoleAdd,
    meta: {
      title: i18n.t("title.role.add"),
      authorize: [user.employee],
    },
  },
  {
    path: "roles/:id",
    name: "RoleEdit",
    component: RoleEdit,
    meta: {
      title: i18n.t("title.role.edit"),
      authorize: [user.employee],
    },
  },
];

export default roles;
