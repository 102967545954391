import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const News = () => import(/* webpackChunkName: "news" */ "@/views/agent/news/index");

const NewsDetails = () => import(/* webpackChunkName: "news" */ "@/views/agent/news/details");

const news = [
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      title: i18n.t("title.news"),
      authorize: [user.agent],
    },
  },
  {
    path: "/news/:id",
    name: "NewsDetails",
    component: NewsDetails,
    meta: {
      title: i18n.t("title.news"),
      authorize: [user.agent],
    },
  },
];

export default news;
