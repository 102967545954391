import debounce from "lodash/debounce";

export default {
  namespaced: true,

  state: {
    isLoading: false,
  },

  mutations: {
    SET_LOADING: debounce(
      (state, status) => {
        state.isLoading = status;
      },
      400,
      {
        leading: true,
      }
    ),
  },
};
