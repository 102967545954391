import i18n from "@/plugins/vue-i18n";
import referralStatus from "@/configs/referralStatus.json";

export default {
  namespaced: true,
  state: {
    statuses: [
      {
        code: referralStatus.new,
        label: i18n.t(`label.referral.statuses.new`),
        color: "primary",
      },
      {
        code: referralStatus.visitSale,
        label: i18n.t(`label.referral.statuses.visitSale`),
        color: "warning",
      },
      {
        code: referralStatus.objectVisit,
        label: i18n.t(`label.referral.statuses.objectVisit`),
        color: "warning",
      },
      {
        code: referralStatus.finalOffer,
        label: i18n.t(`label.referral.statuses.finalOffer`),
        color: "warning",
      },
      {
        code: referralStatus.pending,
        label: i18n.t(`label.referral.statuses.pending`),
        color: "warning",
      },
      {
        code: referralStatus.won,
        label: i18n.t(`label.referral.statuses.won`),
        color: "success",
      },
      {
        code: referralStatus.lost,
        label: i18n.t(`label.referral.statuses.lost`),
        color: "danger",
      },
    ],
  },
};
