import { render, staticRenderFns } from "./AppNotify.vue?vue&type=template&id=6b74d91a&scoped=true&"
import script from "./AppNotify.vue?vue&type=script&lang=js&"
export * from "./AppNotify.vue?vue&type=script&lang=js&"
import style0 from "./AppNotify.vue?vue&type=style&index=0&id=6b74d91a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b74d91a",
  null
  
)

export default component.exports