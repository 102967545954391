import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const EmployeeResetPassword = () =>
  import(/* webpackChunkName: "employeeProfile" */ "@/views/employee/profile/resetPassword");

const resetPassword = [
  {
    path: "reset-password",
    name: "EmployeeResetPassword",
    component: EmployeeResetPassword,
    meta: {
      title: i18n.t("title.profile.resetPassword"),
      authorize: [user.employee],
    },
  },
];

export default resetPassword;
