import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const AgentReferrals = () =>
  import(/* webpackChunkName: "referrals" */ "@/views/agent/referrals/index");

const AgentReferralAdd = () =>
  import(/* webpackChunkName: "referrals" */ "@/views/agent/referrals/add");

const ReferralDetails = () =>
  import(/* webpackChunkName: "referrals" */ "@/views/agent/referrals/details");

const referrals = [
  {
    path: "/referrals",
    name: "AgentReferrals",
    component: AgentReferrals,
    meta: {
      title: i18n.t("title.referral.orders"),
      authorize: [user.agent],
    },
  },
  {
    path: "/referrals/add",
    name: "AgentReferralAdd",
    component: AgentReferralAdd,
    meta: {
      title: i18n.t("title.referral.add"),
      authorize: [user.agent],
    },
  },
  {
    path: "/referrals/:id",
    name: "ReferralDetails",
    component: ReferralDetails,
    meta: {
      title: i18n.t("title.referral.order"),
      authorize: [user.agent],
    },
  },
];

export default referrals;
