import Vue from "vue";
import Vuex from "vuex";

// import the auto exporter
import commonModules from "@/store/modules";
import publicModules from "@/store/modules/public";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...commonModules,
    ...publicModules,
  },
});
