import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./services/api.service";

// Markdown
import "github-markdown-css/github-markdown.css";
import "highlight.js/styles/github.css";

import hljs from "highlight.js";
import markdown from "markdown-it";
import markdownHighlight from "markdown-it-highlightjs";

const md = markdown({ breaks: true, html: true });

Vue.filter("markdown", function (value) {
  if (!value) return "";

  md.use(markdownHighlight, { hljs });

  return md.render(value);
});

// Google Tag Manager
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTM_POINTER_ID,
    params: {
      send_page_view: false,
    },
  },
});

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import "@/plugins/perfect-scrollbar";
import "@/plugins/clipboard";

// Vue 3rd party plugins
import i18n from "./plugins/vue-i18n";
import vuetify from "./plugins/vuetify";
import "./plugins/portal-vue";
import "./plugins/bootstrap-vue";
import "./plugins/vue-perfect-scrollbar";
import "./plugins/inline-svg";
import "./plugins/apexcharts";
import "./plugins/metronic";
import "@/plugins/vue-notification";
import "@/plugins/vue-cleave";
import "@mdi/font/css/materialdesignicons.css";
import "./mixins/globalMixins";

// Vue apollo
import VueApollo from "vue-apollo";
import apolloClient from "@/plugins/apollo-vue";

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.config.productionTip = false;

ApiService.init();

window.instanciaVue = new Vue({
  i18n,
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
