import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/store/modules/layout.module";

Vue.mixin({
  computed: {
    ...mapGetters(["layoutConfig", "layoutDefaultConfig"]),
  },

  methods: {
    ...mapActions([SET_BREADCRUMB, "updateLayoutConfig"]),

    $setBreadcrumbs(routeNames = [], withParentTitle = false) {
      const ROUTE_LEVEL = 2;
      const breadcrumbs = routeNames.map((routeName) => {
        const { route, href } = this.$router.resolve({ name: routeName });

        return {
          title: route.meta.title,
          route: href,
        };
      });

      if (withParentTitle) {
        const maxRouteLevel = this.$route.matched.length;
        const parentRoute = this.$route.matched[maxRouteLevel - ROUTE_LEVEL];

        breadcrumbs.push({ title: parentRoute.meta.title });
      } else {
        breadcrumbs.push({ title: this.$route.meta.title });
      }

      this[SET_BREADCRUMB](breadcrumbs);
    },

    $setMaxContentWidth(width = null) {
      this.updateLayoutConfig({
        path: "content.maxWidth.value",
        value: width,
      });
    },

    $resetMaxContentWidth() {
      this.updateLayoutConfig({
        path: "content.maxWidth.value",
        value: this.layoutDefaultConfig("content.maxWidth.default"),
      });
    },
  },
});
