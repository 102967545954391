import employee from "@/router/pages/employee";
import agent from "@/router/pages/agent";

const MainLayout = () => import(/* webpackChunkName: "main" */ "@/layouts/main");

const mainLayout = [
  {
    path: "/",
    name: "MainLayout",
    component: MainLayout,
    children: [...employee, ...agent],
  },
];

export default mainLayout;
