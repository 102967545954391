import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const EmployeeList = () =>
  import(/* webpackChunkName: "employees" */ "@/views/employee/employees/index");
const EmployeeAdd = () =>
  import(/* webpackChunkName: "employees" */ "@/views/employee/employees/add");
const EmployeeEdit = () =>
  import(/* webpackChunkName: "employees" */ "@/views/employee/employees/edit");

const employees = [
  {
    path: "employees",
    name: "EmployeeList",
    component: EmployeeList,
    meta: {
      title: i18n.t("title.employee.list"),
      authorize: [user.employee],
    },
  },
  {
    path: "employees/add",
    name: "EmployeeAdd",
    component: EmployeeAdd,
    meta: {
      title: i18n.t("title.employee.add"),
      authorize: [user.employee],
    },
  },
  {
    path: "employees/:id",
    name: "EmployeeEdit",
    component: EmployeeEdit,
    meta: {
      title: i18n.t("title.employee.edit"),
      authorize: [user.employee],
    },
  },
];

export default employees;
