export const AUTH_TOKEN = "token";
export const AUTH_TOKEN_EXPIRES = "token-expires";
export const VERSION_HASH = "version-hash";

export const getAuthToken = () => {
  return window.localStorage.getItem(AUTH_TOKEN);
};

export const getAuthTokenExpiresTime = () => {
  return window.localStorage.getItem(AUTH_TOKEN_EXPIRES);
};

export const saveAuthToken = (token, expires) => {
  window.localStorage.setItem(AUTH_TOKEN, token);
  window.localStorage.setItem(AUTH_TOKEN_EXPIRES, expires);
};

export const destroyAuthToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN);
  window.localStorage.removeItem(AUTH_TOKEN_EXPIRES);
};

export const getVersionHash = () => {
  return window.localStorage.getItem(VERSION_HASH);
};

export const saveVersionHash = (hash) => {
  window.localStorage.setItem(VERSION_HASH, hash);
};

export default {
  AUTH_TOKEN,
  AUTH_TOKEN_EXPIRES,
  VERSION_HASH,
  getAuthToken,
  getAuthTokenExpiresTime,
  saveAuthToken,
  destroyAuthToken,
  getVersionHash,
  saveVersionHash,
};
