import referral from "@/router/pages/referral";

const ReferralLayout = () => import(/* webpackChunkName: "referral" */ "@/layouts/referral");

const referralLayout = [
  {
    path: "/",
    name: "ReferralLayout",
    redirect: { name: "ReferralForm" },
    component: ReferralLayout,
    children: [...referral],
  },
];

export default referralLayout;
