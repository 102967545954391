import ApiService from "@/services/api.service";

const NEW_USER_STATE = "new-user-state";

export default {
  state: {
    profile: {},
    isNewUser: true,
  },

  getters: {
    newUserState(state) {
      return state.isNewUser;
    },

    userProfile(state) {
      return state.profile;
    },

    userRequisites(state) {
      const { firstName, lastName, detail } = state.profile;
      const { middleName, taxNumber, iban } = detail;

      return { firstName, lastName, middleName, taxNumber, iban };
    },

    userId(state) {
      return state.profile.id;
    },

    userHash(state) {
      return state.profile.detail?.hash;
    },

    userFullName(state) {
      const firstName = state.profile.firstName || "";
      const lastName = state.profile.lastName || "";

      return `${firstName} ${lastName}`;
    },

    userTgToken(state) {
      return state.profile.detail?.tgToken;
    },

    userTgBotSubscribe(state) {
      return state.profile.detail?.tgBotSubscribe;
    },

    userSymbols(state) {
      const firstNameSymbol = state.profile.firstName?.[0] || "";
      const lastNameSymbol = state.profile.lastName?.[0] || "";

      return `${firstNameSymbol}${lastNameSymbol}`;
    },

    userEmail(state) {
      return state.profile.email;
    },

    userRole(state) {
      return state.profile.roles ? state.profile.roles[0].name : "";
    },

    userType(state) {
      return state.profile.type;
    },

    userLanguage(state) {
      return state.profile.language;
    },

    userAgentType(state) {
      return state.profile.detail.type;
    },

    userAgentAccrualPercentage(state) {
      return state.profile.detail.accrualPercentage;
    },
  },

  mutations: {
    SET_PROFILE(state, profile = {}) {
      state.profile = profile;
    },

    SET_LANGUAGE(state, { language = null }) {
      state.profile.language = language;
    },

    SET_NEW_USER_STATE(state, userState) {
      const newUserState = window.localStorage.getItem(NEW_USER_STATE);

      if (userState || newUserState !== null) {
        state.isNewUser = false;
      }
    },

    SAVE_NEW_USER_STATE() {
      window.localStorage.setItem(NEW_USER_STATE, Number(false));
    },
  },

  actions: {
    async getProfile(context) {
      const response = await ApiService.get("/auth/profile");

      context.commit("SET_PROFILE", response.data);
    },

    async setPassword(context, data) {
      const response = await ApiService.post("/auth/set-password", data);

      context.commit("SET_PROFILE", response.data);
    },

    removeProfile(context) {
      context.commit("SET_PROFILE");
    },
  },
};
