import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const Instruction = () =>
  import(/* webpackChunkName: "instruction" */ "@/views/agent/instruction/index");

const instruction = [
  {
    path: "/instruction",
    name: "Instruction",
    component: Instruction,
    meta: {
      title: i18n.t("title.instruction"),
      authorize: [user.agent],
    },
  },
];

export default instruction;
