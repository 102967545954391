import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    localeCodes: {
      "balance.read_own": "label.permission.balance.read_own",
      "balance.read": "label.permission.balance.read",
      "referral.read": "label.permission.referral.read",
      "referral.read_own": "label.permission.referral.read_own",
      "referral.update": "label.permission.referral.update",
      "referral.delete": "label.permission.referral.delete",
      "referral.update_own": "label.permission.referral.update_own",
      "withdrawal.read": "label.permission.withdrawal.read",
      "withdrawal.read_own": "label.permission.withdrawal.read_own",
      "withdrawal.create": "label.permission.withdrawal.create",
      "withdrawal.approve": "label.permission.withdrawal.approve",
      "withdrawal.reject": "label.permission.withdrawal.reject",
      "transaction.read": "label.permission.transaction.read",
      "transaction.read_own": "label.permission.transaction.read_own",
      "transaction.create": "label.permission.transaction.create",
      "transaction.update": "label.permission.transaction.update",
      "transaction.delete": "label.permission.transaction.delete",
      "agent.create": "label.permission.agent.create",
      "agent.read": "label.permission.agent.read",
      "agent.update": "label.permission.agent.update",
      "agent.delete": "label.permission.agent.delete",
      "agent.read_own": "label.permission.agent.read_own",
      "agent.update_own": "label.permission.agent.update_own",
      "agent.auth_by": "label.permission.agent.auth_by",
      "employee.create": "label.permission.employee.create",
      "employee.read": "label.permission.employee.read",
      "employee.update": "label.permission.employee.update",
      "employee.delete": "label.permission.employee.delete",
      "employee.auth_by": "label.permission.employee.auth_by",
      "employee.get_crm_sync_user": "label.permission",
      "employee.set_crm_sync_user": "label.permission",
      "role.create": "label.permission.role.create",
      "role.read": "label.permission.role.read",
      "role.update": "label.permission.role.update",
      "role.delete": "label.permission.role.delete",
      "role.permissions": "label.permission.role.permissions",
      "requisite.read_own": "label.permission.requisite.read_own",
      "requisite.save_own": "label.permission.requisite.save_own",
    },
  },

  getters: {
    permissionLabel(state) {
      const { localeCodes } = state;

      return (permission) =>
        i18n.te(localeCodes[permission]) ? i18n.t(localeCodes[permission]) : permission;
    },
  },
};
