import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const ResetPassword = () =>
  import(/* webpackChunkName: "profile" */ "@/views/agent/profile/resetPassword");

const resetPassword = [
  {
    path: "reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: i18n.t("title.profile.resetPassword"),
      authorize: [user.agent],
    },
  },
];

export default resetPassword;
