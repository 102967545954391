import { TYPE_VALUES } from "@/api/payments";
import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    agentsFilters: null,
    referralsFilters: null,
    paymentsFilters: {
      withdrawals: null,
      transactions: null,
    },
    employeesFilters: null,
    agentTypes: [
      {
        slug: "active",
        label: i18n.t("agentTypes.active"),
      },
      {
        slug: "passive",
        label: i18n.t("agentTypes.passive"),
      },
      {
        slug: "inactive",
        label: i18n.t("agentTypes.inactive"),
      },
    ],
  },
  mutations: {
    SET_AGENTS_FILTERS(state, filters) {
      state.agentsFilters = filters;
    },

    SET_REFERRALS_FILTERS(state, filters) {
      state.referralsFilters = filters;
    },

    SET_PAYMENTS_FILTERS(state, { type, filters }) {
      if (type === TYPE_VALUES.withdrawals) {
        state.paymentsFilters.withdrawals = filters;
      } else {
        state.paymentsFilters.transactions = filters;
      }
    },

    SET_EMPLOYEES_FILTERS(state, filters) {
      state.employeesFilters = filters;
    },
  },
};
