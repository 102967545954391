import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const Requisites = () =>
  import(/* webpackChunkName: "profile" */ "@/views/agent/profile/requisites");

const requisites = [
  {
    path: "requisites",
    name: "Requisites",
    component: Requisites,
    meta: {
      title: i18n.t("title.profile.requisites"),
      authorize: [user.agent],
    },
  },
];

export default requisites;
