import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import { addHours, getUnixTime } from "date-fns";
import router from "@/router";
import user from "@/configs/user.config.json";
import homePage from "@/configs/homePage.config.json";

const MILLISECONDS_IN_SECOND = 1000;

export default {
  state: {
    token: JwtService.getAuthToken(),
    expires: JwtService.getAuthTokenExpiresTime(),
  },

  getters: {
    isAuthenticatedUser(state) {
      return !!state.token;
    },

    tokenValidityTime(state) {
      return Number(state.expires);
    },
  },

  mutations: {
    SET_AUTH(state, { token, expires }) {
      state.token = token;

      if (!expires) {
        const now = Date.now();
        const newDate = addHours(now, 1);

        state.expires = getUnixTime(newDate);
      } else {
        state.expires = expires;
      }

      JwtService.saveAuthToken(state.token, state.expires);
      // JwtService.saveVersionHash(userAuthData.version_hash);

      ApiService.setHeader();
    },

    PURGE_AUTH(state) {
      state.token = null;
      state.expires = null;
    },
  },

  actions: {
    async login(context, userData) {
      const response = await ApiService.post("/auth/login", userData);

      context.commit("SET_AUTH", response.data.data);

      await context.dispatch("getProfile");

      const { userType } = context.rootGetters;
      const homePageRouteName = userType === user.employee ? homePage.employee : homePage.agent;

      router.push({ name: homePageRouteName });
    },

    async signUp(context, userData) {
      await ApiService.post("/auth/signup", userData, {
        withNotify: true,
        delaySuccessNotify: true,
      });

      await router.push({ name: "Login" });
    },

    async refreshAuth(context) {
      const response = await ApiService.post("/auth/refresh");

      context.commit("SET_AUTH", response.data.data);
    },

    async resetPassword(context, userData) {
      return ApiService.patch("/auth/reset-password", userData);
    },

    async verifyAuth(context) {
      const { isAuthenticatedUser, tokenValidityTime } = context.getters;
      const now = Math.floor(Date.now() / MILLISECONDS_IN_SECOND);

      if (isAuthenticatedUser) {
        ApiService.setHeader();

        if (now > tokenValidityTime) {
          await context.dispatch("refreshAuth");
        }
      } else {
        await context.dispatch("clearAuth");
      }
    },

    clearAuth(context) {
      const { userType } = context.rootGetters;
      const authPageRouteName = userType === user.employee ? "EmployeeLogin" : "Login";

      context.commit("PURGE_AUTH");

      JwtService.destroyAuthToken();

      context.dispatch("removeProfile");

      router.push({ name: authPageRouteName });
    },

    async logout(context) {
      await ApiService.post("/auth/logout");

      context.dispatch("clearAuth");
    },
  },
};
