import i18n from "@/plugins/vue-i18n";

const EmployeeLogin = () =>
  import(/* webpackChunkName: "employeeAuth" */ "@/views/employee/auth/login");

const login = [
  {
    path: "/admin/login",
    name: "EmployeeLogin",
    component: EmployeeLogin,
    meta: {
      title: i18n.t("title.auth.login"),
    },
  },
];

export default login;
