import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const EmployeeProfileEdit = () =>
  import(/* webpackChunkName: "employeeProfile" */ "@/views/employee/profile/edit");

const profileEdit = [
  {
    path: "edit",
    name: "EmployeeProfileEdit",
    component: EmployeeProfileEdit,
    meta: {
      title: i18n.t("title.profile.profileEdit"),
      authorize: [user.employee],
    },
  },
];

export default profileEdit;
