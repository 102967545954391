import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";
import dashboard from "@/router/pages/employee/dashboard";
import employees from "@/router/pages/employee/employees";
import agents from "@/router/pages/employee/agents";
import referrals from "@/router/pages/employee/referrals";
import payments from "@/router/pages/employee/payments";
import roles from "@/router/pages/employee/roles";
import profile from "@/router/pages/employee/profile";
import documents from "@/router/pages/employee/documents";

export default [
  {
    path: "/admin",
    name: "Employee",
    component: () => import("@/views/employee"),
    meta: {
      title: i18n.t("title.employee.cabinet"),
      authorize: [user.employee],
    },
    children: [
      ...dashboard,
      ...employees,
      ...agents,
      ...referrals,
      ...payments,
      ...roles,
      ...profile,
      ...documents,
    ],
  },
];
