import employeeAuth from "@/router/pages/employee/auth";
import agentAuth from "@/router/pages/agent/auth";

const Auth = () => import(/* webpackChunkName: "auth" */ "@/layouts/auth");

const authLayout = [
  {
    path: "/",
    name: "AuthLayout",
    redirect: { name: "Login" },
    component: Auth,
    children: [...agentAuth],
  },
  {
    path: "/admin",
    name: "EmployeeAuthLayout",
    redirect: { name: "EmployeeLogin" },
    component: Auth,
    children: [...employeeAuth],
  },
];

export default authLayout;
