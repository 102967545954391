import i18n from "@/plugins/vue-i18n";

const ForgotPassword = () =>
  import(/* webpackChunkName: "auth" */ "@/views/agent/auth/forgotPassword");

const forgotPassword = [
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: i18n.t("title.auth.forgotPassword"),
    },
  },
];

export default forgotPassword;
