import profileEdit from "@/router/pages/agent/profile/profileEdit";
import changePassword from "@/router/pages/agent/profile/changePassword";
import resetPassword from "@/router/pages/agent/profile/resetPassword";
import notifications from "@/router/pages/agent/profile/notifications";
import requisites from "@/router/pages/agent/profile/requisites";
import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const profile = [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/agent/profile"),
    meta: {
      title: i18n.t("title.profile._"),
      authorize: [user.agent],
    },
    children: [
      ...profileEdit,
      ...changePassword,
      ...resetPassword,
      ...notifications,
      ...requisites,
    ],
  },
];

export default profile;
