import i18n from "@/plugins/vue-i18n";
import agentStatus from "@/configs/agentStatus.json";
import { TYPE_VALUES } from "@/api/payments";

const ALL_NEWS_ID = "all-news-id";
const VIEWED_NEWS_ID = "viewed-news-id";

export default {
  namespaced: true,
  state: {
    statuses: [
      {
        code: agentStatus.new,
        label: i18n.t(`label.agent.statuses.new`),
        color: "primary",
      },
      {
        code: agentStatus.contractSigned,
        label: i18n.t(`label.agent.statuses.contractSigned`),
        color: "warning",
      },
      {
        code: agentStatus.active,
        label: i18n.t(`label.agent.statuses.active`),
        color: "success",
      },
    ],
    referralsFilters: null,
    paymentsFilters: {
      withdrawals: null,
      transactions: null,
    },
    allNewsId: [],
    viewedNewsId: [],
  },
  mutations: {
    SET_REFERRALS_FILTERS(state, filters) {
      state.referralsFilters = filters;
    },

    SET_PAYMENTS_FILTERS(state, { type, filters }) {
      if (type === TYPE_VALUES.withdrawals) {
        state.paymentsFilters.withdrawals = filters;
      } else {
        state.paymentsFilters.transactions = filters;
      }
    },

    SAVE_ALL_NEWS_ID(state, newsId) {
      state.allNewsId = newsId;
      window.localStorage.setItem(ALL_NEWS_ID, state.allNewsId);
    },

    SET_VIEWED_NEWS_ID(state, newsId) {
      const viewedNewsId = window.localStorage.getItem(VIEWED_NEWS_ID);

      if (newsId) {
        state.viewedNewsId.push(newsId);
      } else if (viewedNewsId) {
        state.viewedNewsId = viewedNewsId.split(",");
      }
    },

    SAVE_VIEWED_NEWS_ID(state) {
      window.localStorage.setItem(VIEWED_NEWS_ID, state.viewedNewsId);
    },
  },
};
