import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const EmployeePayments = () =>
  import(/* webpackChunkName: "employeePayments" */ "@/views/employee/payments/index");
const EmployeeAddTransaction = () =>
  import(/* webpackChunkName: "employeePayments" */ "@/views/employee/payments/add");
const EmployeeEditTransaction = () =>
  import(/* webpackChunkName: "employeePayments" */ "@/views/employee/payments/edit");

const payments = [
  {
    path: "payments",
    name: "EmployeePayments",
    component: EmployeePayments,
    meta: {
      title: i18n.t("title.payments._"),
      authorize: [user.employee],
    },
  },
  {
    path: "payments/add",
    name: "EmployeeAddTransaction",
    component: EmployeeAddTransaction,
    meta: {
      title: i18n.t("title.payments.add"),
      authorize: [user.employee],
    },
  },
  {
    path: "payments/:id",
    name: "EmployeeEditTransaction",
    component: EmployeeEditTransaction,
    meta: {
      title: i18n.t("title.payments.edit"),
      authorize: [user.employee],
    },
  },
];

export default payments;
