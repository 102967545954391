import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const Advices = () => import(/* webpackChunkName: "advices" */ "@/views/agent/advices/index");

const advices = [
  {
    path: "/advices",
    name: "Advices",
    component: Advices,
    meta: {
      title: i18n.t("title.advices"),
      authorize: [user.agent],
    },
  },
];

export default advices;
