import profileEdit from "./profileEdit";
import changePassword from "./changePassword";
import resetPassword from "./resetPassword";
import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const profile = [
  {
    path: "profile",
    name: "EmployeeProfile",
    component: () => import("@/views/employee/profile"),
    meta: {
      title: i18n.t("title.profile._"),
      authorize: [user.employee],
    },
    children: [...profileEdit, ...changePassword, ...resetPassword],
  },
];

export default profile;
