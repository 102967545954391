import ApiService from "@/services/api.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SEARCH(context, queryUrl) {
      return new Promise((resolve) => {
        ApiService.get(queryUrl).then((res) => {
          resolve(res.data.data);
        });
      });
    },
  },
};
