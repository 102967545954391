import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const AgentList = () => import(/* webpackChunkName: "agents" */ "@/views/agent/agents/index");
const AgentAdd = () => import(/* webpackChunkName: "agents" */ "@/views/agent/agents/add");
const AgentEdit = () => import(/* webpackChunkName: "agents" */ "@/views/agent/agents/edit");

const agents = [
  {
    path: "/agents",
    name: "CompanyAgentList",
    component: AgentList,
    meta: {
      title: i18n.t("title.agent.list"),
      authorize: [user.agent],
    },
  },
  {
    path: "/agents/add",
    name: "CompanyAgentAdd",
    component: AgentAdd,
    meta: {
      title: i18n.t("title.agent.add"),
      authorize: [user.agent],
    },
  },
  {
    path: "/agents/:id",
    name: "CompanyAgentEdit",
    component: AgentEdit,
    meta: {
      title: i18n.t("title.agent.edit"),
      authorize: [user.agent],
    },
  },
];

export default agents;
