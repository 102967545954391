import ApiService from "@/services/api.service";

export default {
  namespaced: true,

  state: {
    agentName: "",
  },

  mutations: {
    SET_AGENT_NAME(state, agentData) {
      state.agentName = agentData?.firstName;
    },
  },

  actions: {
    async createReferral(context, referralData) {
      const data = {
        ...referralData,
        agent_hash: referralData.agentHash,
      };

      const formData = new FormData();

      for(let k in data) {
        formData.append(k, data[k] ? data[k] : '');
      }

      return await ApiService.post("/referrals", formData, {}, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });
    },

    async saveView(context, agentHash) {
      const response = await ApiService.put("/referrals/view", agentHash);

      context.commit("SET_AGENT_NAME", response.data.data);
    },
  },
};
