import i18n from "@/plugins/vue-i18n";

const EmployeeForgotPassword = () =>
  import(/* webpackChunkName: "employeeAuth" */ "@/views/employee/auth/forgotPassword");

const forgotPassword = [
  {
    path: "/admin/forgot-password",
    name: "EmployeeForgotPassword",
    component: EmployeeForgotPassword,
    meta: {
      title: i18n.t("title.auth.forgotPassword"),
    },
  },
];

export default forgotPassword;
