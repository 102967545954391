import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/views/agent/dashboard");

const dashboard = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: i18n.t("title.statistics"),
      authorize: [user.agent],
    },
  },
];

export default dashboard;
