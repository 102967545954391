import i18n from "@/plugins/vue-i18n";
import transactionType from "@/configs/transactionType.json";

export default {
  namespaced: true,
  state: {
    types: [
      {
        code: transactionType.accrual,
        label: i18n.t(`label.operation.types.accrual`),
      },
      {
        code: transactionType.accrualCancel,
        label: i18n.t(`label.operation.types.accrualCancel`),
      },
      {
        code: transactionType.withdrawal,
        label: i18n.t(`label.operation.types.withdrawal`),
      },
    ],
  },
};
