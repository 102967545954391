<template>
  <div class="app-root">
    <AppLoader v-if="isLoading" />
    <AppNotify />
    <router-view />
    <FeedbackWidget v-if="isShownWidget" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/modules/layout.module";

import user from "@/configs/user.config.json";

import AppLoader from "@/components/loader/AppLoader";
import AppNotify from "@/components/notification/AppNotify";
import FeedbackWidget from "@/components/FeedbackWidget";

export default {
  name: "MetronicVue",

  components: {
    AppLoader,
    AppNotify,
    FeedbackWidget,
  },

  data() {
    return {
      isShownWidget: false,
    };
  },

  computed: {
    ...mapState("loader", ["isLoading"]),
    ...mapGetters(["userType"]),

    isAgent() {
      return this.userType === user.agent;
    },

    routerName() {
      return this.$route.name;
    },
  },

  watch: {
    routerName: "onChangeRouteName",
  },

  mounted() {
    /**
     * this is to override the layouts config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layouts.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },

  methods: {
    onChangeRouteName() {
      const routeNames = [
        "Login",
        "EmployeeLogin",
        "ForgotPassword",
        "EmployeeForgotPassword",
        "ReferralForm",
      ];

      if (this.isAgent && !routeNames.includes(this.routerName)) {
        this.isShownWidget = true;
      } else {
        if (this.isShownWidget) location.reload();
        this.isShownWidget = false;
      }
    },
  },
};
</script>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Vue bootstrap table
@import "assets/sass/custom/bootstrap-table";

body {
  font-family: "Roboto", sans-serif;
}

.app-root {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
}
.modal-backdrop {
  opacity: 0.5;
}
*:focus {
  outline: none;
}

/* sortable component styles */
.sortable-ghost {
  background: #eaeffe !important;
}
.sortable-drag {
  background: #f6f7fb !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
