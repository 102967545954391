<template>
  <notifications
    group="notify"
    class="notify"
    animation-type="velocity"
    :animation="animation"
    :position="position"
  >
    <template v-slot:body="props">
      <div :data-cy="`${props.item.type}-notify`" class="notify-body" :class="props.item.type">
        <a data-cy="close-notify" class="close" @click="props.close">
          <i class="close-icon fa fa-times" />
        </a>
        <div v-if="props.item.title" class="title">
          {{ props.item.title }}
        </div>
        <div class="content" v-html="props.item.text" />
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  data: () => ({
    animation: null,
    yPosition: "top",
    xPosition: "center",
  }),

  computed: {
    position() {
      return [this.yPosition, this.xPosition];
    },
  },

  mounted() {
    this.animation = {
      enter(element) {
        let height = element.clientHeight;

        return {
          height: [height, 0],
          opacity: [0.5 + 0.5, 0],
        };
      },
      leave: {
        height: 0,
        opacity: 0,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.notify-body {
  margin-top: 20px;
  margin-right: 10px;
  border-radius: 8px;
  padding: 12px 16px;

  &.error {
    background: rgba(229, 77, 66, 1);
  }
  &.warning {
    background: rgba(255, 193, 7, 1);
  }
  &.success {
    background: rgba(32, 201, 151, 1);
  }
  .title {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
  .content {
    padding: 0;
    color: white;
    font-weight: 500;
  }
  .close {
    cursor: pointer;
    color: white;
    text-shadow: none;
    line-height: 13px;
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: rgb(33 33 33 / 75%);
    box-shadow: 0 0 5px rgb(255 255 255 / 10%);
    left: 24px;
    bottom: 20px;
    opacity: 1;
    transition: visibility 0.1s, opacity 0.1s linear;

    &-icon {
      color: white;
      font-size: 12px;
      position: absolute;
      left: 6px;
      top: 4px;
    }
  }
}
</style>
