import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const ReferralList = () =>
  import(/* webpackChunkName: "referrals" */ "@/views/employee/referrals/index");
const ReferralAdd = () =>
  import(/* webpackChunkName: "referrals" */ "@/views/employee/referrals/add");
const ReferralEdit = () =>
  import(/* webpackChunkName: "referrals" */ "@/views/employee/referrals/edit");

const referrals = [
  {
    path: "referrals",
    name: "ReferralList",
    component: ReferralList,
    meta: {
      title: i18n.t("title.referral.orders"),
      authorize: [user.employee],
    },
  },
  {
    path: "referrals/add",
    name: "ReferralAdd",
    component: ReferralAdd,
    meta: {
      title: i18n.t("title.referral.add"),
      authorize: [user.employee],
    },
  },
  {
    path: "referrals/:id",
    name: "ReferralEdit",
    component: ReferralEdit,
    meta: {
      title: i18n.t("title.referral.edit"),
      authorize: [user.employee],
    },
  },
];

export default referrals;
