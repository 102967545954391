import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const EmployeeDashboard = () =>
  import(/* webpackChunkName: "employeeDashboard" */ "@/views/employee/dashboard");

const dashboard = [
  {
    path: "dashboard",
    name: "EmployeeDashboard",
    component: EmployeeDashboard,
    meta: {
      title: i18n.t("title.dashboard"),
      authorize: [user.employee],
    },
  },
];

export default dashboard;
