import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const DocumentsView = () => import(/* webpackChunkName: "roles" */ "@/views/employee/documents/index");

const documents = [
  {
    path: "documents",
    name: "Documents",
    component: DocumentsView,
    meta: {
      title: i18n.t("title.documents"),
      authorize: [user.employee],
    },
  },
];

export default documents;
