import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    stations: [
      {
        label: i18n.t(`label.station.types.10kW`),
        value: "10",
      },
      {
        label: i18n.t(`label.station.types.20kW`),
        value: "20",
      },
      {
        label: i18n.t(`label.station.types.30kW`),
        value: "30",
      },
      {
        label: i18n.t(`label.station.types.other`),
        value: "other",
      },
    ],
  },
};
