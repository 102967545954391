import i18n from "@/plugins/vue-i18n";
import user from "@/configs/user.config.json";

const EmployeeChangePassword = () =>
  import(/* webpackChunkName: "employeeProfile" */ "@/views/employee/profile/changePassword");

const changePassword = [
  {
    path: "change-password",
    name: "EmployeeChangePassword",
    component: EmployeeChangePassword,
    meta: {
      title: i18n.t("title.profile.changePassword"),
      authorize: [user.employee],
    },
  },
];

export default changePassword;
